<template>
    <div class="container">
        <div class="heading">
            <h1>{{ $t('Privacy') }}</h1>
        </div>

        <div class="flex">
            <h2>{{ $t('terms-flex-1') }}</h2>
            <p>{{ $t('terms-flex-2') }}</p>
            <p>{{ $t('terms-flex-3') }}</p>
            <p>{{ $t('terms-flex-4') }}</p>
            <p>{{ $t('terms-flex-5') }}</p>
            <p>{{ $t('terms-flex-6') }}</p>

            <h2>{{ $t('terms-flex-7') }}</h2>
            <p>{{ $t('terms-flex-8') }}</p>
            <p>{{ $t('terms-flex-9') }}</p>
            <p>{{ $t('terms-flex-10') }}</p>
            <p>{{ $t('terms-flex-11') }}</p>
            <p>{{ $t('terms-flex-12') }}</p>
            <p>{{ $t('terms-flex-13') }}</p>

            <h2>{{ $t('terms-flex-14') }}</h2>
            <p>{{ $t('terms-flex-15') }}</p>
            <p>{{ $t('terms-flex-16') }}</p>
            <p>{{ $t('terms-flex-17') }}</p>
            <p>{{ $t('terms-flex-18') }}</p>
            <p>{{ $t('terms-flex-19') }}</p>
            <p>{{ $t('terms-flex-20') }}</p>
            <p>{{ $t('terms-flex-21') }}</p>
            <p>{{ $t('terms-flex-22') }}</p>
            <p>{{ $t('terms-flex-23') }}</p>
            <p>{{ $t('terms-flex-24') }}</p>
            <p>{{ $t('terms-flex-25') }}</p>
            <p>{{ $t('terms-flex-26') }}</p>

            <h2>{{ $t('terms-flex-27') }}</h2>
            <p>{{ $t('terms-flex-28') }}</p>
            <p>{{ $t('terms-flex-29') }}</p>
            <p>{{ $t('terms-flex-30') }}</p>
            <p>{{ $t('terms-flex-31') }}</p>
            <p>{{ $t('terms-flex-32') }}</p>

            <h2>{{ $t('terms-flex-33') }}</h2>
            <p>{{ $t('terms-flex-34') }}</p>
            <p>{{ $t('terms-flex-35') }}</p>
            <p>{{ $t('terms-flex-36') }}</p>
            <p>{{ $t('terms-flex-37') }}</p>
            <p>{{ $t('terms-flex-38') }}</p>

            <h2>{{ $t('terms-flex-39') }}</h2>
            <p>{{ $t('terms-flex-40') }}</p>
            <p>{{ $t('terms-flex-41') }}</p>
            <p>{{ $t('terms-flex-42') }}</p>
            <ul>
                <li>{{ $t('terms-flex-43') }}</li>
                <li>{{ $t('terms-flex-44') }}</li>
                <li>{{ $t('terms-flex-45') }}</li>
                <li>{{ $t('terms-flex-46') }}</li>
                <li>{{ $t('terms-flex-47') }}</li>
                <li>{{ $t('terms-flex-48') }}</li>
            </ul>
            <p>{{ $t('terms-flex-49') }}</p>

            <h2>{{ $t('terms-flex-50') }}</h2>
            <p>{{ $t('terms-flex-51') }}</p>
            <p>{{ $t('terms-flex-52') }}</p>
            <ul>
                <li>{{ $t('terms-flex-53') }}</li>
                <li>{{ $t('terms-flex-54') }}</li>
                <li>{{ $t('terms-flex-55') }}</li>
                <li>{{ $t('terms-flex-56') }}</li>
                <li>{{ $t('terms-flex-57') }}</li>
                <li>{{ $t('terms-flex-58') }}</li>
                <li>{{ $t('terms-flex-59') }}</li>
                <li>{{ $t('terms-flex-60') }}</li>
            </ul>
            <p>{{ $t('terms-flex-61') }}</p>
            <p>{{ $t('terms-flex-62') }}</p>

            <h2>{{ $t('terms-flex-63') }}</h2>
            <p>{{ $t('terms-flex-64') }}</p>
            <p>{{ $t('terms-flex-65') }}</p>
            <p>{{ $t('terms-flex-66') }}</p>

            <h2>{{ $t('terms-flex-67') }}</h2>
            <p>{{ $t('terms-flex-68') }}</p>
            <ul>
                <li>{{ $t('terms-flex-69') }}</li>
                <li>{{ $t('terms-flex-70') }}</li>
                <li>{{ $t('terms-flex-71') }}</li>
                <li>{{ $t('terms-flex-72') }}</li>
                <li>{{ $t('terms-flex-73') }}</li>
                <li>{{ $t('terms-flex-74') }}</li>
                <li>{{ $t('terms-flex-75') }}</li>
            </ul>

            <h2>{{ $t('terms-flex-76') }}</h2>
            <p>{{ $t('terms-flex-77') }}</p>
            <p>{{ $t('terms-flex-78') }}</p>
            <p>{{ $t('terms-flex-79') }}</p>
            <p>{{ $t('terms-flex-80') }}</p>
            <p>{{ $t('terms-flex-81') }}</p>
            <p>{{ $t('terms-flex-82') }}</p>
            <p>{{ $t('terms-flex-83') }}</p>

            <h2>{{ $t('terms-flex-84') }}</h2>
            <p>{{ $t('terms-flex-85') }}</p>
            <p>{{ $t('terms-flex-86') }}</p>
            <p>{{ $t('terms-flex-87') }}</p>

            <h2>{{ $t('terms-flex-88') }}</h2>
            <p>{{ $t('terms-flex-89') }}</p>
            <p>{{ $t('terms-flex-90') }}</p>
            <p>{{ $t('terms-flex-91') }}</p>
            <p>{{ $t('terms-flex-92') }}</p>

            <h2>{{ $t('terms-flex-93') }}</h2>
            <p>{{ $t('terms-flex-94') }}</p>
            <p>{{ $t('terms-flex-95') }}</p>
            <p>{{ $t('terms-flex-96') }}</p>
            <p>{{ $t('terms-flex-97') }}</p>
            <ul>
                <li>{{ $t('terms-flex-98') }}</li>
                <li>{{ $t('terms-flex-99') }}</li>
                <li>{{ $t('terms-flex-100') }}</li>
                <li>{{ $t('terms-flex-101') }}</li>
            </ul>

            <h2>{{ $t('terms-flex-102') }}</h2>
            <p>{{ $t('terms-flex-103') }}@hollandbesttravel.org</p>
        </div>

    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
h1 {
    font-size: 2rem;
    letter-spacing: 2px;
    font-weight: 300;
}

h2 {
    font-size: 1.3rem;
}

p {
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 1px;
    margin-top: 1em;
}

.heading {
    /* border: 1px solid black; */
    padding: 3em;
}

.heading h1 {
    font-size: 2.5rem;
    font-weight: 400;
}

.flex {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    text-align: left;
    padding: 2rem 2rem;
}

@media screen and (max-width: 500px) {
    h1 {
        font-size: 1.8rem;
    }

    p {
        font-size: 1rem;
    }

    .heading h1 {
        font-size: 2rem;
    }
}
</style>